var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GmapMap',{ref:"map",staticStyle:{"height":"100%","width":"100%"},attrs:{"center":_vm.center,"zoom":_vm.zoom,"map-type-id":"terrain","options":{
    styles: this.googleMapStyle,
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: true,
    fullscreenControl: true,
    disableDefaultUi: false
  }}},[(_vm.showMarkers)?[_c('gmap-custom-marker',{attrs:{"marker":_vm.pickupLatLng}},[_c('address-marker',{attrs:{"title":_vm.$t('publicRideTracking.booking.pickup'),"address":_vm.pickup.address,"popup-position":_vm.pickup.latitude > _vm.destination.latitude ? 'top' : 'bottom',"circle-color":_vm.primaryColor}})],1),_c('gmap-custom-marker',{attrs:{"marker":_vm.destinationLatLng}},[_c('address-marker',{attrs:{"title":_vm.$t('publicRideTracking.booking.dropOff'),"address":_vm.destination.address,"popup-position":_vm.destination.latitude > _vm.pickup.latitude ? 'top' : 'bottom',"circle-color":_vm.primaryColor}})],1),_c('gmap-circle',{attrs:{"center":_vm.pickupLatLng,"radius":5000,"visible":true,"options":{fillColor:_vm.primaryColor,fillOpacity:0.05, strokeOpacity:0.0}}}),_c('gmap-circle',{attrs:{"center":_vm.destinationLatLng,"radius":5000,"visible":true,"options":{fillColor:_vm.primaryFontColor,fillOpacity:0.05, strokeOpacity:0.0}}}),(_vm.map)?_c('google-map-curve',{attrs:{"map":_vm.map,"point1":_vm.pickup,"point2":_vm.destination,"stroke-color":_vm.primaryColor}}):_vm._e(),(_vm.driverPosition)?_c('gmap-custom-marker',{attrs:{"marker":_vm.driverPositionLatLng}},[_c('img',{attrs:{"src":_vm.taxiIconUrl,"width":"32px"}})]):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }