<template>
  <div class="marker" :class="markerClass()">
    <div class="circle" :style="{borderColor: circleColor}"></div>
    <div class="popup_container">
      <p class="address_title">{{ title }}</p>
      <p class="address">{{ address }}</p>
      <div class="arrow"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    popupPosition: String,
    title: String,
    address: String,
    circleColor: String,
  },
  methods: {
    markerClass() {
      const position = this.popupPosition || 'top';
      const classObj = {};
      classObj[`popup_${position}`] = true;
      return classObj;
    },
  },
};
</script>

<style scoped>
  .marker {
    position: relative;
    font-family: 'Inter', sans-serif;
  }
  .circle{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #FFFFFF;
    border: 5px solid #000000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  p{
    margin: 0;
  }
  .popup_container {
    position: absolute;
    transform: translate(0%,-100%) translate(-40px,-25px)!important;
    width: 200px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
    padding: 12px 15px;
    box-sizing: border-box;
  }
  .popup_bottom .popup_container{
    transform: translate(-100%, 0%) translate(40px, 25px)!important;
  }
  .popup_top .popup_container{
    transform: translate(0%,-100%) translate(-40px,-25px)!important;
  }
  .address_title{
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #AAAAAA;
  }
  .address{
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #333333;
  }

  .arrow:after{
    content:'';
    position: absolute;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: 10px solid #fff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }
  .popup_top .arrow:after{
    left: 30px;
    bottom: -7px;
    border-top: 10px solid #fff;
    border-bottom: 0;
  }
  .popup_bottom .arrow:after{
    right: 30px;
    top: -7px;
    border-bottom: 10px solid #fff;
    border-top: 0;
  }
</style>
