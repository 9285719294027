<template>

</template>

<script>
import { gmapApi } from 'vue2-google-maps';

export default {
  props: {
    map: Object,
    point1: Object,
    point2: Object,
    strokeColor: String,
  },
  data() {
    return {
      line: null,
    };
  },
  mounted() {
    this.line = this.drawCurve(this.point1, this.point2, this.map, this.google);
  },
  beforeDestroy() {
    this.line.setMap(null);
  },
  methods: {
    // eslint-disable-next-line max-lines-per-function
    drawCurve(P1, P2, map, google) {
      const vm = this;
      const point1 = new google.maps.LatLng(P1.latitude, P1.longitude);
      const point2 = new google.maps.LatLng(P2.latitude, P2.longitude);
      const GmapsCubicBezier = function GmapsCubicBezier(latlong1, latlong2, latlong3, latlong4, resolution, mapObj) {
        const lat1 = latlong1.lat();
        const long1 = latlong1.lng();
        const lat2 = latlong2.lat();
        const long2 = latlong2.lng();
        const lat3 = latlong3.lat();
        const long3 = latlong3.lng();
        const lat4 = latlong4.lat();
        const long4 = latlong4.lng();

        const points = [];

        for (let it = 0; it <= 1; it += resolution) {
          points.push(this.getBezier({
            x: lat1,
            y: long1,
          }, {
            x: lat2,
            y: long2,
          }, {
            x: lat3,
            y: long3,
          }, {
            x: lat4,
            y: long4,
          }, it));
        }
        const path = [];
        for (let i = 0; i < points.length - 1; i++) {
          path.push(new google.maps.LatLng(points[i].x, points[i].y));
          path.push(new google.maps.LatLng(points[i + 1].x, points[i + 1].y, false));
        }

        const Line = new google.maps.Polyline({
          path,
          geodesic: true,
          strokeColor: vm.strokeColor,
          strokeOpacity: 0.8,
          strokeWeight: 5,
        });

        Line.setMap(mapObj);

        return Line;
      };


      GmapsCubicBezier.prototype = {
        B1(t) {
          return t * t * t;
        },
        B2(t) {
          return 3 * t * t * (1 - t);
        },
        B3(t) {
          return 3 * t * (1 - t) * (1 - t);
        },
        B4(t) {
          return (1 - t) * (1 - t) * (1 - t);
        },
        getBezier(C1, C2, C3, C4, percent) {
          const pos = {};
          pos.x = C1.x * this.B1(percent) + C2.x * this.B2(percent) + C3.x * this.B3(percent) + C4.x * this.B4(percent);
          pos.y = C1.y * this.B1(percent) + C2.y * this.B2(percent) + C3.y * this.B3(percent) + C4.y * this.B4(percent);
          return pos;
        },
      };

      const lineLength = google.maps.geometry.spherical.computeDistanceBetween(point1, point2);
      const lineHeading = google.maps.geometry.spherical.computeHeading(point1, point2);
      let lineHeading1;
      let lineHeading2;
      if (lineHeading < 0) {
        lineHeading1 = lineHeading + 45;
        lineHeading2 = lineHeading + 135;
      } else {
        lineHeading1 = lineHeading + -45;
        lineHeading2 = lineHeading + -135;
      }
      const pA = google.maps.geometry.spherical.computeOffset(point1, lineLength / 2.2, lineHeading1);
      const pB = google.maps.geometry.spherical.computeOffset(point2, lineLength / 2.2, lineHeading2);

      return new GmapsCubicBezier(point1, pA, pB, point2, 0.01, map);
    },
  },
  computed: {
    google: gmapApi,
  },
};
</script>

<style scoped>

</style>
