<template>
  <GmapMap
    :center="center"
    :zoom="zoom"
    ref="map"
    map-type-id="terrain"
    style="height: 100%; width: 100%"
    :options="{
      styles: this.googleMapStyle,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: true,
      fullscreenControl: true,
      disableDefaultUi: false
    }"
  >

    <template v-if="showMarkers">
      <gmap-custom-marker :marker="pickupLatLng">
        <address-marker
          :title="$t('publicRideTracking.booking.pickup')"
          :address="pickup.address"
          :popup-position="pickup.latitude > destination.latitude ? 'top' : 'bottom'"
          :circle-color="primaryColor"
        ></address-marker>
      </gmap-custom-marker>

      <gmap-custom-marker :marker="destinationLatLng">
        <address-marker
          :title="$t('publicRideTracking.booking.dropOff')"
          :address="destination.address"
          :popup-position="destination.latitude > pickup.latitude ? 'top' : 'bottom'"
          :circle-color="primaryColor"
        ></address-marker>
      </gmap-custom-marker>

      <gmap-circle
        :center="pickupLatLng"
        :radius="5000"
        :visible="true"
        :options="{fillColor:primaryColor,fillOpacity:0.05, strokeOpacity:0.0}"
      ></gmap-circle>

      <gmap-circle
        :center="destinationLatLng"
        :radius="5000"
        :visible="true"
        :options="{fillColor:primaryFontColor,fillOpacity:0.05, strokeOpacity:0.0}"
      ></gmap-circle>


      <google-map-curve
        v-if="map"
        :map="map"
        :point1="pickup"
        :point2="destination"
        :stroke-color="primaryColor"
      ></google-map-curve>

      <gmap-custom-marker
        v-if="driverPosition"
        :marker="driverPositionLatLng"
      >
        <img :src="taxiIconUrl" width="32px">
      </gmap-custom-marker>
    </template>

  </GmapMap>
</template>

<script>
import { mapGetters } from 'vuex';
import { gmapApi } from 'vue2-google-maps';
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import googleMapStyle from './googleMapStyle';
import AddressMarker from './AddressMarker.vue';
import GoogleMapCurve from './GoogleMapCurve.vue';

export default {
  name: 'GoogleMap',
  components: {
    GmapCustomMarker,
    AddressMarker,
    GoogleMapCurve,
  },
  mixins: [],
  props: {
    pickup: Object,
    destination: Object,
    driverPosition: Object,
    taxiIconUrl: String,
    showMarkers: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      zoom: 10,
      map: null,
    };
  },
  mounted() {
    this.$refs.map.$mapPromise.then((map) => {
      this.map = map;

      // eslint-disable-next-line func-names
      this.google.maps.event.addListenerOnce(map, 'bounds_changed', function () {
        this.setZoom(this.getZoom() - 1);
      });

      this.fitBounds();

      window.onresize = () => {
        this.fitBounds();
      };
    });
  },
  methods: {
    latLngToProviderFormat(latLng) {
      return { lng: latLng.longitude, lat: latLng.latitude };
    },
    fitBounds() {
      if (this.map) {
        const bounds = new this.google.maps.LatLngBounds(); // eslint-disable-line
        if (this.center) bounds.extend(this.center);
        if (this.pickupLatLng) bounds.extend(this.pickupLatLng);
        if (this.destinationLatLng) bounds.extend(this.destinationLatLng);
        if (this.driverPositionLatLng) bounds.extend(this.driverPositionLatLng);

        this.map.fitBounds(bounds);

        this.map.setCenter(bounds.getCenter());
        this.map.setZoom(this.map.getZoom() - 1);
      }
    },
  },
  computed: {
    ...mapGetters({
      primaryColor: 'bookingChannel/primaryColor',
      primaryFontColor: 'bookingChannel/primaryFontColor',
    }),
    google: gmapApi,
    googleMapStyle() {
      return googleMapStyle;
    },
    center() {
      return this.latLngToProviderFormat(this.pickup);
    },
    pickupLatLng() {
      return this.latLngToProviderFormat(this.pickup);
    },
    destinationLatLng() {
      return this.latLngToProviderFormat(this.destination);
    },
    driverPositionLatLng() {
      if (this.driverPosition) {
        return this.latLngToProviderFormat(this.driverPosition);
      }
      return null;
    },
  },
};
</script>

<style scoped>

</style>
